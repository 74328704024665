<template>
  <v-container id="signinup-form" class="fill-height">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" sm="8" md="8" class="">
        <v-card class="evelation-12 card">
          <v-row class="">
            <v-col cols="12" md="8" class="pt-6 pb-6">
              <v-card-text>
                <v-form class="signup-form-form" v-model="valid1">
                  <h2
                    class="text-center display-1 mb-10"
                    :class="`${bgColor}--text`"
                  >
                    Reset Password
                  </h2>
                  <v-text-field
                    v-model="password"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show3 ? 'text' : 'password'"
                    name="input-10-2"
                    label="Password"
                    hint="At least 8 characters"
                    counter
                    class="input-group--focused"
                    @click:append="show3 = !show3"
                    prepend-icon="mdi-lock"
                  ></v-text-field>
                  <v-text-field
                    v-model="password2"
                    :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[
                      rules.required,
                      rules.min,
                      passwordConfirmationRule
                    ]"
                    :type="show4 ? 'text' : 'password'"
                    name="input-10-2"
                    label="Ulangi Password"
                    hint="At least 8 characters"
                    counter
                    prepend-icon="mdi-lock"
                    @click:append="show4 = !show4"
                  ></v-text-field>
                  <div class="text-center mt-6">
                    <v-btn
                      large
                      :color="bgColor"
                      dark
                      :disabled="!valid1"
                      @click="reset"
                      >Reset Password</v-btn
                    >
                  </div>
                </v-form>
              </v-card-text>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="darken-2 vcenter"
              :class="`${bgColor}`"
            >
              <div>
                <v-card-text :class="`${fgColor}--text`">
                  <h1 class="text-center headline mb-3">Lupa Password?</h1>
                  <h5 class="text-center overline mb-3">
                    Please Sign Up to continue
                  </h5>
                </v-card-text>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "resetPassword",
  props: {
    source: {
      type: String,
      default: ""
    },
    bgColor: {
      type: String,
      default: "indigo"
    },
    fgColor: {
      type: String,
      default: "white"
    }
  },
  data: () => ({
    valid1: false,
    password: "",
    password2: "",
    show3: false,
    show4: false,
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 8 || "Min 8 characters",
      emailMatch: () => "The email and password you entered don't match"
    }
  }),
  computed: {
    passwordConfirmationRule() {
      return this.password === this.password2 || "Password match";
    }
  },
  methods: {
    reset() {
      this.$store
        .dispatch("auth/resetPassword", {
          token: this.$route.params.kode,
          password: this.password
        })
        .then(succ => {
          this.$alert(succ.message, "Success", "success");
          this.$router.push("/login");
        })
        .catch(err => {
          this.$alert(err.response.data, "Error", "error");
        });
    }
  }
};
</script>

<style scoped lang="scss">
.signup-form-form {
  max-width: 23rem;
  margin: 0 auto;
}
.card {
  overflow: hidden;
}
.vcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
